<template>
    <div class="goldWithdraw">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">金币提现</div>
            <router-link tag="div" to="bankCard" class="actBtn">账号管理</router-link>
        </div>
        <div class="body">
            <div class="cardBox">
                <div class="cardNameBox">
                    <div class="name">{{bankCardInfo.name}}</div>
                    <div class="bankName">{{bankCardInfo.bankType}}</div>
                </div>
                <div class="bankCardNo">{{bankCardInfo.cardNum}}</div>
            </div>
            <div class="banlance">金币余额 {{banlance}}</div>
            <div class="proportion">提现比例：10金币=1元</div>
            <div class="withdrawAmount">
                <van-field placeholder="请输入提现金额" type="number" v-model="withdrawAmount" @input="changeAmount">
                    <template #button>
                        <div class="allBtn" @click="setMaxAmount">全部</div>
                    </template>
                </van-field>
            </div>
            <div class="tip">提现手续费为{{handlingFee}}%，实际到账为{{realAmount}}元</div>
            <div class="btn" @click="withdraw">提交</div>
            <div class="remind">
                1.每次提现金额必须满足{{minMoney}}-{{maxMoney}}的额度才能提现<br>
                2.提交提现申请后3个工作日内到账<br>
                3.请确保银行卡信息正确，若错误会导致提现失败
            </div>
        </div>
    </div>
</template>

<script>
    import {
        queryBankCard,
        queryWithdrawMethod,
        withdraw,
    } from '@/api/user.js'
    export default {
        data() {
            return {
                bankCardInfo: {}, // 银行卡信息
                banlance: 0, // 收益余额
                withdrawAmount: "", // 提现金额
                maxMoney: 0, // 最大提现金额
                minMoney: 0, // 最小提现金额
                handlingFee: 0, // 手续费
                formalitiesAmount: 0, //手续费金额
                realAmount: 0, // 实际到账金额
            }
        },
        created() {
            this.getWalletInfo();
            this.queryBankCard();
            this.queryWithdrawMethod();
        },
        methods: {
            // 查询银行卡
            async queryBankCard() {
                let ret = await this.$Api(queryBankCard);
                if (ret && ret.code == 200) {
                    this.bankCardInfo = ret.data.bankInfo;
                }
            },
            // 获取钱包信息
            async getWalletInfo() {
                await this.$store.dispatch("user/setWallet");
                this.banlance = this.$store.getters.walletInfo.income;
            },
            // 查询提现金额区间
            async queryWithdrawMethod() {
                this.$store.dispatch("app/GET_LOADING", true);
                let ret = await this.$Api(queryWithdrawMethod);
                if (ret && ret.code == 200) {
                    let arr = ret.data.info ? ret.data.info : [];
                    arr.forEach(i => {
                        if (i.wtdrMode == 3) {
                            this.maxMoney = i.maxAmount / 100;
                            this.minMoney = i.minAmount / 100;
                            this.handlingFee = i.rate;
                        }
                    });
                }
                this.$store.dispatch("app/GET_LOADING", false);
            },
            //设置最大金额
            setMaxAmount() {
                if (this.banlance <= this.maxMoney * 10) {
                    this.withdrawAmount = this.banlance;
                } else if (this.banlance >= this.maxMoney * 10) {
                    this.withdrawAmount = this.maxMoney * 10;
                }
                this.changeAmount();
            },
            // 计算金额
            changeAmount() {
                let money = this.withdrawAmount / 10;
                this.formalitiesAmount = (money * this.handlingFee / 100).toFixed(2);
                this.realAmount = (money - this.formalitiesAmount).toFixed(2);
            },
            // 提现
            async withdraw() {
                if (!this.bankCardInfo.cardNum && this.bank) {
                    this.$toast('请添加银行卡');
                    return;
                }

                let req = {
                    cardNo: this.bankCardInfo.cardNum,
                    bankCode: this.bankCardInfo.bankCode,
                    coin: Number(this.withdrawAmount),
                    realName: this.bankCardInfo.name,
                    productType: 0, //0 站群 1 棋牌
                    withdrawType: 3,
                }
                if (!this.withdrawAmount) {
                    this.$toast('请输入提现金额');
                    return;
                }
                if (this.withdrawAmount > this.banlance) {
                    this.$toast('余额不足');
                    return;
                }
                if (this.withdrawAmount / 10 > this.maxMoney) {
                    this.$toast(`单笔提现最大${this.maxMoney}`);
                    return;
                }
                if (this.withdrawAmount / 10 < this.minMoney) {
                    this.$toast(`单笔提现小${this.minMoney}`);
                    return;
                }
                this.$store.dispatch("app/GET_LOADING", true);
                let ret = await this.$Api(withdraw, req);
                if (ret && ret.code === 200) {
                    this.$toast('已提交申请，请耐心等待')
                    this.getWalletInfo();
                    this.withdrawAmount = "";
                } else {
                    this.$toast(ret.tip || '提现失败，请稍后再试');
                }
                this.$store.dispatch("app/GET_LOADING", false);
            }
        }
    }
</script>

<style lang='scss' scoped>
    .goldWithdraw {
        .navbar {
            background: $vermillion;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 16px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .navbar_btn {
                margin-right: 28px;
            }

            .navbar_title {
                font-weight: 600;
                flex: 2;
            }

            .actBtn {
                font-size: 14px;
            }
        }

        .body {
            padding: 16px 16px 0;

            .cardBox {
                padding: 10px 20px;
                height: 60px;
                border-radius: 4px;
                background: $vermillion;
                color: $white1;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .cardNameBox {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;

                    .bankName {
                        color: rgba(255, 255, 255, 0.9);
                    }
                }

                .bankCardNo {
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .banlance {
                font-size: 13px;
                margin-top: 10px;
            }

            .proportion {
                font-size: 13px;
                color: $greyishBrown;
                margin-top: 5px;
            }

            .btn {
                width: 100%;
                height: 42px;
                line-height: 42px;
                border-radius: 27px;
                font-size: 20px;
                color: $white1;
                background: $vermillion;
                text-align: center;
            }

            .withdrawAmount {
                border-bottom: 1px solid rgba(219, 225, 232, 0.74);
                margin-top: 56px;

                .allBtn {
                    font-size: 16px;
                    color: $vermillion;
                }
            }

            .tip {
                margin: 10.5px 0 15px;
                font-size: 10px;
                color: #8b8b8b;
            }

            .remind {
                margin-top: 45px;
                font-size: 12px;
                line-height: 18px;
                color: $greyishBrown;
            }
        }

        .mt40 {
            margin-top: 40px;
        }

        .mt20 {
            margin-top: 20px;
        }
    }
</style>